import { useContext, useEffect, useState } from 'react';
import '../../App.scss';
import { useHistory, useLocation } from "react-router-dom";
import UserContext from '../../protected-components/contexts/UserContext';
import Validators from '../../shared/validators/validator';
import JWTService from '../../shared/services/jwt-services/jwt-service';
import LoginService from './login-services/login-service';
import TenantContext from '../../protected-components/contexts/TenantCotext';
import PropagateLoader from "react-spinners/PropagateLoader";
import LoadingPage from '../../shared/components/LoadingPage';
import userService from '../../shared/services/user-services/userService';
import PasswordField from '../../shared/components/PasswordField';
import forgetPasswordService from '../forget-password/forget-password-services/forget-password-service';
import refreshTokenService from '../../shared/services/api-services/refresh-token-service';

function Login(props) {

    const history = useHistory();
    const location = useLocation();
    const [User, setUser] = useContext(UserContext);
    const [Tenant, setTenant] = useContext(TenantContext);

    const [loginButton, setLoginButton] = useState('Sign In');
    const [dynamicStyle, setDynamicStyle] = useState('#FFFFFF');
    const [loading, setLoading] = useState(true);
    const [verifyInProgress, setVerifyInProgress] = useState(false);

    let loadingSpinner = <PropagateLoader
        css={'left: 50%; right : 50%; top: -5px'}
        size={10}
        color={"#FFFFFF"}
        loading={true}
    />

    if (Tenant.colors != null && dynamicStyle === '#FFFFFF') {
        setDynamicStyle(Tenant.colors.background);
    }

    useEffect(() => {
        if (JWTService.getAccessToken() != null) {
            if (location.pathname !== "/") {
                history.push(`/${props.basePath}`);
            }
            //setLoading(false);
        } else {
            setLoading(true);
            if (JWTService.getRefreshToken() != null && Tenant.clientId != null && !verifyInProgress) {
                console.log("request send");
                setVerifyInProgress(true);
                refreshTokenService.refreshToken().then(_token => {
                    if (JWTService.getAccessToken() != null) {
                        if (location.pathname !== "/") {
                            history.push(`/${props.basePath}`);
                        }
                    } else {
                        setLoading(false);
                        setUser(null);
                    }
                }).catch(err => {
                    setLoading(false);
                    setUser(null);
                });
            }else {
                setLoading(false);
                setUser(null);
            }
        }
    }, []);

    const [LoginUser, setLoginUser] = useState(
        {
            username: "",
            password: "",
            newPassword: "",
            confirmNewPassword: "",
            code: "",
            newPasswordTextbox: false,
            newPasswordShow: false,
            passwordShow: false,
            showNewComfirmPassword: false,
            passwordStrength: false,
            codeTextbox: false,
            errorMessage: "",
            keepSignIn: true
        }
    );
    const [resendTimeout, setResendTimeout] = useState(0);

    const reload = () => {
        window.location.reload();
    }

    const keepSignIn = () => {
        setLoginUser({ ...LoginUser, keepSignIn: !LoginUser.keepSignIn });
    }
    const goToForgetPassword = (e) => {
        e.preventDefault();
        history.push(`/${props.basePath}/forget-password`);
    }
    const gotoSignUpStudent = (e) => {
        e.preventDefault();
        if (process.env.REACT_APP_ENV === "localhost") {
            window.location.href = `http://localhost:4200/${props.basePath}/student/sign-up`;
        } else if (process.env.REACT_APP_ENV === "prod") {
            window.location.href = `https://www.student.99ticks.com/${props.basePath}/student/sign-up`;
        } else {
            window.location.href = `https://${process.env.REACT_APP_ENV}.student.99ticks.com/${props.basePath}/student/sign-up`;
        }
    }
    const resendCode = () => {
        setLoginUserError("");
        setLoginButton('Verify');
        let user =
        {
            "clientId": Tenant.clientId,
            "clientSecret": ""
        };
        if (!Validators.isEmptyOrNullOrUndefined(LoginUser.username)) {
            user.username = LoginUser.username;
            forgetPasswordService.resendCode(user).then(data => {
                setLoginButton('Verify');
                startCount(5);
            }).catch(error => {
                setLoginButton('Verify');
                let response = error.response.data;
                setLoginUserError(response.message);
            });
        }
    }
    const startCount = (num) => {
        if (num !== -1) {
            setTimeout(function () {
                setResendTimeout({ resendTimeout: num });
                startCount(num - 1);
            }, 1000);
        }
    }

    const login = (user) => {
        LoginService.signIn(user).then(data => {
            var responseData = data.data.data;
            JWTService.setAccessToken(responseData.accessToken, Tenant.clientId);
            //console.log(responseData.refreshToken);
            if (responseData.refreshToken) {
                JWTService.setRefreshToken(responseData.refreshToken, responseData.deviceKey);
            }
            const redirect = JWTService.getRedirectUrl();
            if (redirect != null) {
                JWTService.removeRedirectUrl();
                window.location.href = redirect;
            } else {
                return userService.getUser().then(loggedUser => {
                    if (loggedUser.userRole && loggedUser.userRole === "Student") {
                        window.location.href = process.env.REACT_APP_STUDENT_URL + props.basePath + "/home";
                    } else if (user.userRole && user.userRole === "Teacher" && HasPermission('P7', loggedUser.permissions)) {
                        window.location.href = process.env.REACT_APP_TUTER_URL + props.basePath + "/home";
                    } else {
                        JWTService.removeRedirectUrl();
                        history.push(`/${props.basePath}`);
                    }
                }).catch(err => {
                    //console.log(err);
                    JWTService.removeTokens();
                    setLoginUserError("Incorrect email or password");
                });
            }
        }).catch(error => {
            //console.log(error.response);
            if(error.response){
                let response = error.response.data;
                if (response.messageKey === "NEW_PASSWORD_REQUIRED") {
                    setLoginButton('Change Password');
                    setLoginUser({ ...LoginUser, newPasswordTextbox: true });
                } else if (response.messageKey === "USER_NOT_VERIFIED") {
                    setLoginButton('Verify');
                    setLoginUser({ ...LoginUser, codeTextbox: true });
                } else if (response.messageKey === "INVALID_PASSWORD_OR_USERNAME") {
                    setLoginUserError("Incorrect email or password");
                } else {
                    setLoginUserError(response.message);
                }
            }else{
                setLoginUserError("Login failed");
                setUser(null);
            }
        });
    }

    const OnSubmit = (event) => {
        JWTService.setDeviceInfo();
        event.preventDefault();
        setLoginUserError("");
        setLoginButton(loadingSpinner);
        let user =
        {
            "clientId": Tenant.clientId,
            "clientSecret": "",
            "keepMe": LoginUser.keepSignIn
        };
        if (!Validators.isEmptyOrNullOrUndefined(LoginUser.username)) {

            user.username = LoginUser.username;
            if (!Validators.isEmptyOrNullOrUndefined(LoginUser.password) && LoginUser.password.length >= 6 &&
                (LoginUser.newPassword === "" || (LoginUser.newPassword && LoginUser.newPassword.length >= 6))) {

                if (LoginUser.newPasswordTextbox === true) {
                    if (LoginUser.passwordStrength === false) {
                        setLoginUserError("Please check your new password strength");
                    } else {
                        //console.log(LoginUser.newPassword , LoginUser.confirmNewPassword );
                        if (LoginUser.newPassword !== "" && LoginUser.confirmNewPassword !== "") {
                            if (LoginUser.newPassword === LoginUser.confirmNewPassword) {
                                user.password = LoginUser.password;
                                user.newPassword = LoginUser.newPassword;
                                login(user);
                            } else {
                                setLoginUserError("New password and confirm password must be match");
                                setLoginButton('Change Password');
                            }
                        } else {
                            setLoginUserError("New password and confirm password are mandatory");
                            setLoginButton('Change Password');
                        }
                    }
                } else if (LoginUser.codeTextbox === true) {
                    user.password = LoginUser.password;
                    const usercode =
                    {
                        "user": { email: LoginUser.username, code: LoginUser.code },
                        "clientId": Tenant.clientId,
                        "clientSecret": ""
                    };
                    LoginService.verifyCode(usercode).then(verify => {
                        //console.log(verify);
                        setLoginButton('Verify');
                        login(user);
                    }).catch(err => {
                        //console.log(err);
                        setLoginUserError("Verification code is Incorrect");
                        setLoginButton('Verify');
                    });
                } else {
                    user.password = LoginUser.password;
                    login(user);
                }
            } else {
                setLoginUserError("Password length is short");
            }
        } else {
            setLoginUserError("Email is empty");
        }
    }
    const setLoginUserError = (message) => {
        setLoginButton('Sign In');
        setLoginUser({ ...LoginUser, errorMessage: message });
    }

    const showPassword = () => {
        setLoginUser({ ...LoginUser, passwordShow: !LoginUser.passwordShow, errorMessage: "" });
    }

    const showNewPassword = () => {
        setLoginUser({ ...LoginUser, newPasswordShow: !LoginUser.newPasswordShow, errorMessage: "" });
    }
    const showNewComfirmPassword = () => {
        setLoginUser({ ...LoginUser, showNewComfirmPassword: !LoginUser.showNewComfirmPassword, errorMessage: "" });
    }


    const OnChangePassword = event => {
        setLoginUser({ ...LoginUser, password: event.target.value.trim(), errorMessage: "" });
    }

    const OnChangeNewPassword = value => {
        const password = value.value.trim();
        setLoginUser({ ...LoginUser, newPassword: password, passwordStrength: (value.errors.length === 0 ? true : false), errorMessage: "" });
    }
    const OnChangeConfirmNewPassword = event => {
        setLoginUser({ ...LoginUser, confirmNewPassword: event.target.value.trim(), errorMessage: "" });
    }

    const OnChangeUsername = event => {
        setLoginUser({ ...LoginUser, username: event.target.value.trim(), errorMessage: "" });
    }

    const OnChangeCode = event => {
        setLoginUser({ ...LoginUser, code: event.target.value.trim(), errorMessage: "" });
    }

    const HasPermission = (id, permissions) => {
        if (User && User.permissions && User.permissions.length > 0) {
            let hasValiPermissionLeve11 = permissions.find(p => p === "PS");
            let hasValiPermissionLevel2 = permissions.find(p => p === "PT");
            let hasValiPermissionLevel3 = permissions.find(p => p === id);
            return !!(hasValiPermissionLeve11 || hasValiPermissionLevel2 || hasValiPermissionLevel3);
        } else {
            return false;
        }
    }

    return (
        <div>
            {!loading ? (
                <div className="full-screen-height container">
                    <div className="mobile-ui d-xl-none d-lg-none d-md-block d-block">
                        <div className="row">
                            <div className="col-12 mt-4 pt-3">
                                <img src={Tenant.logo} alt="" height="90px" />
                            </div>
                            <div className="col-12 mt-4 pt-3 pl-3 pr-3 signin-form-contents">
                                <div className="signin-form-mobile">

                                    <form className="form-signin" onSubmit={OnSubmit} autoComplete="true">
                                        <h4>Sign In</h4>
                                        {!LoginUser.newPasswordTextbox && !LoginUser.codeTextbox ?
                                            <div className="custom-margin mb-4">
                                                <label className="sr-only">Email address</label>
                                                <input type="email" value={LoginUser.username} onChange={OnChangeUsername} className="form-control mb-4" autoComplete="on" placeholder="Email address" required />

                                                <label className="sr-only">Password</label>
                                                <div className="input-group mb-3">
                                                    <input type={LoginUser.passwordShow === true ? 'text' : 'password'} value={LoginUser.password} onChange={OnChangePassword} className="form-control" autoComplete="on" placeholder="Password" required />
                                                    <i onClick={showPassword} className={LoginUser.passwordShow ? 'fa fa-eye-slash showHidePassword' : 'fa fa-eye showHidePassword'} id="togglePassword" />
                                                </div>
                                            </div>
                                            : ""
                                        }
                                        {LoginUser.newPasswordTextbox ?
                                            <div>
                                                <label className="sr-only">New Password</label>
                                                <PasswordField className="form-control" fieldId="togglePassword" type={LoginUser.newPasswordShow ? 'text' : 'password'} label="New Password" placeholder="New password" onStateChanged={OnChangeNewPassword} thresholdLength={8} minStrength={2} required />
                                                <i onClick={showNewPassword} className={LoginUser.newPasswordShow ? 'fa fa-eye-slash showNewPassoword' : 'fa fa-eye showNewPassoword'} id="togglePassword" />
                                            </div>
                                            : ""
                                        }
                                        {LoginUser.newPasswordTextbox ?
                                            <div>
                                                <label className="sr-only">Confirm Password</label>
                                                <div className="input-group mb-3">
                                                    <input type={LoginUser.showNewComfirmPassword === true ? 'text' : 'password'} value={LoginUser.confirmNewPassword} onChange={OnChangeConfirmNewPassword} className="form-control" autoComplete="off" placeholder="Confirm password" required />
                                                    <i onClick={showNewComfirmPassword} className={LoginUser.showNewComfirmPassword ? 'fa fa-eye-slash showHidePassword' : 'fa fa-eye showHidePassword'} id="togglePassword" />
                                                </div>
                                            </div>
                                            : ""
                                        }
                                        {LoginUser.codeTextbox ?
                                            <div className="pb-3">
                                                <span className="d-block p-3  form-hint">Account confirmation is required. please check your email for the verification code</span>
                                                <label className="sr-only">Verification Code</label>
                                                <input type="number" value={LoginUser.code} onChange={OnChangeCode} className="form-control" placeholder="Enter verification code" autoComplete="off" required />
                                                <div className="row mt-3">
                                                    <div className="col-lg-12  text-right">
                                                        <label className="font-weight-bold forget-text " >
                                                            <div className="link" onClick={reload}>
                                                                <span className="forget-p">back to sign in <i className="fas fa-sign-in-alt" /></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                {resendTimeout === 0 ?

                                                    <a className="resend-login" style={{ textDecoration: 'none' }} href={void (0)} onClick={resendCode}>
                                                        <span className="d-inline-block">Send Code</span>
                                                    </a>
                                                    :
                                                    <span>
                                                        <p className="forget-p resend-login">try again in {resendTimeout}s</p>
                                                    </span>
                                                }

                                            </div> : ""
                                        }
                                        {!Validators.isEmptyOrNullOrUndefined(LoginUser.errorMessage) ?

                                            <div className="alert alert-danger custom-margin" role="alert login-error-message">
                                                {LoginUser.errorMessage}
                                            </div>

                                            :
                                            ""
                                        }
                                        {!LoginUser.newPasswordTextbox && !LoginUser.codeTextbox ?
                                            <div className="row mb-4 mt-3">
                                                <div className="col-lg-6">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox"
                                                            defaultChecked={LoginUser.keepSignIn}
                                                            onChange={keepSignIn} type="checkbox" value="remember-me" className="custom-control-input" id="customCheck1" />
                                                        <label className="custom-control-label pt-1 pl-2" htmlFor="customCheck1"> Keep me signed in</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 text-right">
                                                    <label className="font-weight-bold forget-text custom-margin">
                                                        <a href=""></a>
                                                        <a href="#" onClick={goToForgetPassword}>
                                                            <p className="forget-p">Forgot password ?</p>
                                                        </a>
                                                    </label>
                                                </div>
                                            </div>
                                            : ""
                                        }

                                        <div className="row">
                                            <div className="col-12 custom-padding">
                                                <button className="btn btn-lg  btn-block login-btn btn-radius custom-btn-color-1" type="submit">
                                                    {loginButton}
                                                </button>
                                            </div>
                                        </div>
                                        {(Tenant.basePath !== '99ticks') && !LoginUser.newPasswordTextbox && !LoginUser.codeTextbox ?
                                            <div>
                                                <div className="row mt-5 mb-2">
                                                    <div className="col-lg-12">
                                                        <h6 className="new-acc-text">Don’t have an account? <a href="#" onClick={gotoSignUpStudent}>Register</a></h6>
                                                    </div>
                                                </div>
                                            </div>

                                            :

                                            ""
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container col-md-6 login-container d-xl-block d-lg-block d-md-none d-none " >
                        <div className="row logo-row">
                            <div className="col-lg-12">
                                <img className="tenant-logo" src={Tenant.logo} alt="" height="90px" />
                            </div>
                        </div>
                        <form className="form-signin pt-0" onSubmit={OnSubmit} autoComplete="true">

                            <h4>Sign In</h4>
                            {!LoginUser.newPasswordTextbox && !LoginUser.codeTextbox ?
                                <div>
                                    <label className="sr-only">Email address</label>
                                    <input type="email" value={LoginUser.username} onChange={OnChangeUsername} className="form-control mb-3" autoComplete="on" placeholder="Email address" required />

                                    <label className="sr-only">Password</label>
                                    <div className="input-group mb-3">
                                        <input type={LoginUser.passwordShow === true ? 'text' : 'password'} value={LoginUser.password} onChange={OnChangePassword} className="form-control" autoComplete="on" placeholder="Password" required />
                                        <i onClick={showPassword} className={LoginUser.passwordShow ? 'fa fa-eye-slash showHidePassword' : 'fa fa-eye showHidePassword'} id="togglePassword" />
                                    </div>
                                </div>
                                : ""
                            }
                            {LoginUser.newPasswordTextbox ?
                                <div>
                                    <label className="sr-only">New Password</label>
                                    <PasswordField className="form-control" fieldId="togglePassword" type={LoginUser.newPasswordShow ? 'text' : 'password'} label="New Password" placeholder="New password" onStateChanged={OnChangeNewPassword} thresholdLength={8} minStrength={2} required />
                                    <i onClick={showNewPassword} className={LoginUser.newPasswordShow ? 'fa fa-eye-slash showNewPassoword' : 'fa fa-eye showNewPassoword'} id="togglePassword" />
                                </div>
                                : ""
                            }
                            {LoginUser.newPasswordTextbox ?
                                <div>
                                    <label className="sr-only">Confirm Password</label>
                                    <div className="input-group mb-3">
                                        <input type={LoginUser.showNewComfirmPassword === true ? 'text' : 'password'} value={LoginUser.confirmNewPassword} onChange={OnChangeConfirmNewPassword} className="form-control" autoComplete="off" placeholder="Confirm password" required />
                                        <i onClick={showNewComfirmPassword} className={LoginUser.showNewComfirmPassword ? 'fa fa-eye-slash showHidePassword' : 'fa fa-eye showHidePassword'} id="togglePassword" />
                                    </div>
                                </div>
                                : ""
                            }
                            {LoginUser.codeTextbox ?
                                <div className="pb-3">
                                    <span className="d-block p-3  form-hint">Account confirmation is required. please check your email for the verification code</span>
                                    <label className="sr-only">Verification Code</label>
                                    <input type="number" value={LoginUser.code} onChange={OnChangeCode} className="form-control" placeholder="Enter verification code" autoComplete="off" required />
                                    <div className="row mt-3">
                                        <div className="col-lg-12  text-right">
                                            <label className="font-weight-bold forget-text " >
                                                <div className="link" onClick={reload}>
                                                    <span className="forget-p">back to sign in <i className="fas fa-sign-in-alt" /></span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    {LoginUser.resendTimeout === 0 ?

                                        <a className="resend-login" style={{ textDecoration: 'none' }} href={void (0)} onClick={resendCode}>
                                            <span className="d-inline-block"  >Send Code</span>
                                        </a>
                                        :
                                        <span>
                                            <p className="forget-p resend-login">{LoginUser.resendTimeout}</p>
                                        </span>
                                    }

                                </div> : ""
                            }
                            {!Validators.isEmptyOrNullOrUndefined(LoginUser.errorMessage) ?

                                <div className="alert alert-danger" role="alert login-error-message">
                                    {LoginUser.errorMessage}
                                </div>

                                :
                                ""
                            }
                            {!LoginUser.newPasswordTextbox && !LoginUser.codeTextbox ?
                                <div className="row mb-4 mt-3">
                                    <div className="col-lg-6">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                defaultChecked={LoginUser.keepSignIn}
                                                onChange={keepSignIn} type="checkbox" value="remember-me" className="custom-control-input" id="customCheck2" />
                                            <label className="custom-control-label pt-1 pl-2" htmlFor="customCheck2"> Keep me signed in</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-right">
                                        <label className="font-weight-bold forget-text">
                                            <a href=""></a>
                                            <a href="#" onClick={goToForgetPassword}>
                                                <p className="forget-p">Forgot password ?</p>
                                            </a>
                                        </label>
                                    </div>
                                </div>
                                : ""
                            }

                            <button className="btn btn-lg  btn-block login-btn btn-radius custom-btn-color-1  " type="submit">
                                {loginButton}
                            </button>

                            {(Tenant.basePath !== '99ticks') && !LoginUser.newPasswordTextbox && !LoginUser.codeTextbox ?
                                <div>
                                    <div className="row mt-5 mb-2">
                                        <div className="col-lg-12">
                                            <h6 className="new-acc-text">Don’t have an account? <a href="#" onClick={gotoSignUpStudent}>Register</a></h6>
                                        </div>
                                    </div>
                                </div>

                                :

                                ""
                            }
                        </form>
                    </div>
                </div>) : <LoadingPage />}
        </div>
    );
}

export default Login;
